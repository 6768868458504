exports.eventsCats = [
  'events-care-health',
  'events-top-100-top-200',
  'events-innovation',
  'events-community',
  'events-renewable',
  'events-women-business',
  'events-charity',
  'events-culture',
  'events-tech',
  'events-apprenticeship',
  'events-business'
];
