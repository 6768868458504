import axios from 'axios';
import animHandler from './landingAnim';
import tilt from './tilt-jquery';
import anime from 'animejs/lib/anime.es.js';

function init() {
  // Get site width on load
  let siteDim;
  document.addEventListener("DOMContentLoaded", function () {
    siteDim = $(window);
  });

  $("a.marketingsub").on("click", (e) => {
    console.log(e);
    e.preventDefault();
    createModal(e.target.dataset.item);
  });

  $(".localstarsbutton").tilt({
    maxTilt: 5,
    perspective: 1000,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    speed: 1200,
    glare: true,
    maxGlare: 0.6,
  });

  $(".hp-card__subcard").tilt({
    maxTilt: 15,
    perspective: 1000,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    speed: 1200,
    glare: true,
    maxGlare: 0.1,
  });

  $(".card").tilt({
    maxTilt: 15,
    perspective: 1000,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    speed: 1200,
    glare: true,
    maxGlare: 0.1,
  });

  $(".market__item").tilt({
    maxTilt: 15,
    perspective: 1000,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    speed: 1200,
    glare: true,
    maxGlare: 0.1,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CAROUSEL/SLIDER
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  if (document.getElementById("landingpage")) {
  // Lottie Settings

  const paramsCreative = {
    container: document.getElementById('creativeLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: creativeAnimationData
  };
  
  
  const creativeLottie = lottie.loadAnimation(paramsCreative);

  const paramsContent = {
    container: document.getElementById('contentLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: contentAnimationData
  };
  
  
  const contentLottie = lottie.loadAnimation(paramsContent);

  const paramsFacebook = {
    container: document.getElementById('facebookLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: facebookAnimationData
  };
  
  
  const fbLottie = lottie.loadAnimation(paramsFacebook);

  const paramsIYA = {
    container: document.getElementById('iyaLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: iyaAnimationData
  };
  
  
  const iyaLottie = lottie.loadAnimation(paramsIYA);
  
  const paramsEvents = {
    container: document.getElementById('eventsLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: eventsAnimationData
  };
  
  
  const eventsLottie = lottie.loadAnimation(paramsEvents);

  
  const paramsVideo = {
    container: document.getElementById('videoLottie'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: videoAnimationData
  };
  
  const videoLottie = lottie.loadAnimation(paramsVideo);


  // Set up Hero carousel, to play initial slie on init
  $(".hero-carousel").on("init reinit", function (event, slick, currentSlide, nextSlide) {
    creativeLottie.goToAndPlay(0);
  });

  $(".hero-carousel").slick({
    centerPadding: "0",
    centerMode: true,
    dots: true,
    arrows: false,
    fade: true,
    speed: 1,
    cssEase: 'linear',
    mobileFirst: true,
    swipeToSlide: true,
    slidesToShow: 1,
    rows: 0,
    autoplay: true,
    autoplaySpeed: 9000,
    adaptiveHeight: true
  });


  // Play other slide afterchange
  $(".hero-carousel").on("afterChange", function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    if (typeof currentSlide == "undefined") currentSlide = 0;

    switch (currentSlide) {
      case 0:
        creativeLottie.goToAndPlay(0);
        break;

      case 1:
        videoLottie.goToAndPlay(0);
        break;

      case 2:
        contentLottie.goToAndPlay(0);
        break;

      // case 3:
      //   eventsLottie.goToAndPlay(0);
      //   break;

      case 3:
        iyaLottie.goToAndPlay(0);
        break;
      
      case 4:
        fbLottie.goToAndPlay(0);
        break;
      }
  });

  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // END OF CAROUSLE/SLIDER
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Ajax call/ Api only works with logged in users
  if (typeof userLogin !== "undefined") {
    // Recent seasonal
    axios
      .get("/api/recent")

      .then(function (response) {
        const recent = response.data;

        for (let i = 0; i < recent.length; i++) {
          const html = `<div class="carousel__item">
					<a href="/products/${recent[i].product.category}/${recent[i].product.urn}" class="carousel__link">
						<img src="${recent[i].product.thumbnail}" alt="" />
					</a>
				</div>`;

          if (document.querySelector("#recent")) {
            document.querySelector("#recent").insertAdjacentHTML("beforeend", html);
          }
        }
      })
      .then(function () {
        // Recent items
        $("#recent").slick({
          centerPadding: "0",
          centerMode: true,
          prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          slidesToShow: 2,
          swipeToSlide: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 4,
              },
            },
          ],
        });

        // Creative Inspiration
        $("#inspiration").slick({
          centerPadding: "0",
          centerMode: true,
          prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          slidesToShow: 2,
          swipeToSlide: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 3,
              },
            },
          ],
        });
      })
      .then(function () {
        $(".recently-added .carousel__item").tilt({
          maxTilt: -40,
          perspective: 1400, easing: "cubic-bezier(.03,.98,.52,.99)",
          speed: 1200,
          glare: true,
          maxGlare: 0.6,
        });

        $("#inspiration").slickLightbox({
          itemSelector: "a",
          navigateByKeyboard: true,
        });

        $("#inspirationalt").slickLightbox({
          itemSelector: "a",
          navigateByKeyboard: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    // Recent training
    axios
      .get("/api/training")
      .then(function (response) {
        const training = response.data;
        let html = ``;

        for (let i = 0; i < training.length; i++) {
          if (typeof training[i].video !== "undefined") {
            html = `
							<div class="carousel__item">
								<a href="/training/${training[i]._id}" class="carousel__link">
									<div class='training-inner'>
										<img src="https://img.youtube.com/vi/${training[i].video}/0.jpg" class="carousel__picture" alt="" />
									</div>
									<h4>${training[i].name}</h4>
								</a>
							</div>
						`;
          } else {
            html = "";
          }

          if (document.querySelector(".training-carousel")) {
            document.querySelector(".training-carousel").insertAdjacentHTML("beforeend", html);
          }
        }
      })
      .then(function () {
        // Training store
        $(".training-carousel").slick({
          centerPadding: "0",
          centerMode: true,
          prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          swipeToSlide: true,
          slidesToShow: 1,
          rows: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
              },
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    // Recent events
    axios
      .get("/api/events")
      .then(function (response) {
        const events = response.data;

        for (let i = 0; i < events[0].products.length; i++) {
          const html = `<div class="carousel__item">
					<a href="/products/${events[0].products[i].category}/${events[0].products[i].urn}" class="carousel__link">
						<img src="${events[0].products[i].thumbnail}" alt="" />
					</a>
				</div>`;

          if (document.querySelector("#events-store")) {
            document.querySelector("#events-store").insertAdjacentHTML("beforeend", html);
          }
        }
      })
      .then(function () {
        // Event store
        $("#events-store").slick({
          centerPadding: "0",
          centerMode: true,
          prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          slidesToShow: 2,
          swipeToSlide: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 5,
              },
            },
          ],
        });
      })
      .then(function () {
        $(".events-store .carousel__item").tilt({
          maxTilt: -40,
          perspective: 1400, easing: "cubic-bezier(.03,.98,.52,.99)",
          speed: 1200,
          glare: true,
          maxGlare: 0.6,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    // Promo
    axios
      .get("/api/inyourarea")
      .then(function (response) {
        const events = response.data;

        for (let i = 0; i < events[0].products.length; i++) {
          const html = `<div class="carousel__item">
					<a href="/products/${events[0].products[i].category}/${events[0].products[i].urn}" class="carousel__link">
						<img src="${events[0].products[i].thumbnail}" alt="" />
					</a>
				</div>`;

          if (document.querySelector("#promo-store")) {
            document.querySelector("#promo-store").insertAdjacentHTML("beforeend", html);
          }
        }
      })
      .then(function () {
        // Event store
        $("#promo-store").slick({
          centerPadding: "0",
          centerMode: true,
          prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          slidesToShow: 2,
          swipeToSlide: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 5,
              },
            },
          ],
        });
      })
      .then(function () {
        $(".promo-store .carousel__item").tilt({
          maxTilt: -40,
          perspective: 1400, easing: "cubic-bezier(.03,.98,.52,.99)",
          speed: 1200,
          glare: true,
          maxGlare: 0.6,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Tilt / category header
  setTimeout(function () {
    // was $(".card, .products__item, .market__item")
    $(".products__item, .market__item").tilt({
    //   maxTilt: -40,
    //   perspective: 1400,
    //   easing: "cubic-bezier(.03,.98,.52,.99)",
    //   speed: 1200,
    //   glare: true,
    //   maxGlare: 0.6,
    maxTilt: 15,
    perspective: 1000,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    speed: 1200,
    glare: true,
    maxGlare: 0.1,
    });
  }, 1500);
  // animHandler.init();
  categoriesFlip();
}

const landingPage = {
  init,
  categoriesFlip,
};

export default landingPage;

function createModal(index) {
  const modalcontents = subcategoryData[index];
  let htmlBody;
  let htmlsource;

  htmlBody = document.querySelector('body');

  htmlsource = `
		<div class="modal marketingsub">
			<div class="modal-content">
				<div class="inner">
					<div class="close-button">Close</div>
					<h1>${modalcontents.title}<span class="white">.</span></h1>
					<div class='sublinks' id='sublinks'>
					</div>
				</div>
			</div>
		</div>`;

  htmlBody.insertAdjacentHTML('beforeend', htmlsource);

  const modal = document.querySelector('.modal');
  const closeButton = document.querySelector('.close-button');

  const linksec = document.getElementById('sublinks');
  linksec.innerHTML = '';

  modalcontents.links.map(link => {
    linksec.innerHTML += `
			<a href="${link.url}"> - ${link.title}</a>
		`;
  });

  function toggleModal() {
    modal.classList.toggle('show-modal');
  }

  function modalDestroy() {
    toggleModal();
    setTimeout(function() {
      modal.remove();
    }, 300);
  }

  function windowOnClick(event) {
    if (event.target === modal) {
      modalDestroy();
    }
  }
  closeButton.addEventListener('click', modalDestroy);
  window.addEventListener('click', windowOnClick);

  toggleModal();
}

const subcategoryData = [
  {
    title: 'Business',
    links: [
      { title: 'Construction', url: '/marketing/construction' },
      {
        title: 'Charity, Community &amp; Care',
        url: '/marketing/charity-community-care',
      },
      { title: 'Employment', url: '/marketing/employment' },
      { title: 'Heating &amp; Energy', url: '/marketing/heating-energy' },
      { title: 'Event Sponsorship', url: '/marketing/event-sponsorship' },
    ],
  },
  {
    title: 'Leisure',
    links: [
      { title: 'Lifestyles', url: '/marketing/lifestyles' },
      { title: 'Food', url: '/marketing/food' },
      { title: 'Drink', url: '/marketing/drink' },
      { title: 'Pets', url: '/marketing/pets' },
      { title: 'Gambling', url: '/marketing/gambling' },
      { title: 'Entertainment', url: '/marketing/entertainment' },
      {
        title: 'Sports &amp; Outdoor Activities',
        url: '/marketing/sports-outdoor-activities',
      },
      { title: 'Gyms', url: '/marketing/gyms' },
      { title: 'Seasonal', url: '/marketing/seasonal' },
      { title: 'Visitor Attractions', url: '/marketing/visitor-attractions' },
      { title: 'Other', url: '/marketing/other' },
    ],
  },
  {
    title: 'Retail',
    links: [
      { title: 'Carpets &amp; Flooring', url: '/marketing/carpets-flooring' },
      { title: 'Electrical', url: '/marketing/electrical' },
      { title: 'Furniture &amp; Home', url: '/marketing/furniture-home' },
      { title: 'Glazing', url: '/marketing/glazing' },
      {
        title: 'Kitchens, Bedrooms &amp; Bathrooms',
        url: '/marketing/kitchens-bedrooms-bathrooms',
      },
      { title: 'Antiques', url: '/marketing/antiques' },
      { title: 'Gardening &amp; DIY', url: '/marketing/gardening-diy' },
    ],
  },
  {
    title: 'Services',
    links: [
      { title: 'Legal', url: '/marketing/legal' },
      { title: 'Financial', url: '/marketing/financial' },
    ],
  },
  {
    title: 'Health &amp; Beauty',
    links: [
      { title: 'Products', url: '/marketing/products' },
      { title: 'Cosmetic Treatments', url: '/marketing/cosmetic-treatments' },
      {
        title: 'Health &amp; Medical Care',
        url: '/marketing/health-medical-care',
      },
      { title: 'Dental', url: '/marketing/dental' },
    ],
  },
];

function categoriesFlip() {
  // Flip out category
  const revealItems = document.querySelectorAll('.card');
  let catChilds;
  let list;
  let activeCatChild;
  let isShowing;
  let altShow = false;
  let catText;
  let self;
  let catImage;

  // Filer filter throught all category on the page
  revealItems.forEach(function(event) {
    // Assign a click event for eeach one of the
    event.addEventListener('click', function(item) {
      // # Checkout if block has subchild
      if (event.querySelector('.sub-card') != null) {
        // Stop click through if parent has sub category
        item.preventDefault();

        // Active state
        if (isShowing === true) {
          // Was the same button click twice or not
          if (this === self) {
            // - Reset text,icon and background
            self.querySelector('.card__heading span').innerHTML = catText;
            self.querySelector('.card__picture').src = catImage;

            self.classList.remove('show');

            // Remove active blocks
            activeCatChild = document.querySelectorAll('.active-cat');
            activeCatChild.forEach(function(child) {
              child.classList.add('fadeOut');
              document.querySelector('.active-cat').remove();
            });

            // Restore opacity once category is closed
            const cardParent = self.parentNode.querySelectorAll('.card');
            self.parentNode.classList.toggle('showing');

            cardParent.forEach(function(cards) {
              cards.style.opacity = '1';
            });

            if (this.parentNode.classList.contains('seasonal-active')) {
              this.parentNode.classList.toggle('seasonal-active');
            }

            if (this.classList.contains('seasonal')) {
              this.parentNode.classList.remove('seasonal-active');
            }

            // Reset and start over
            isShowing = false;
            self = '';
            catText = '';
            catImage = '';
            altShow = false;
          } else {
            // - Reset text,icon and background
            self.querySelector('.card__heading span').innerHTML = catText;
            self.querySelector('.card__picture').src = catImage;
            self.classList.remove('show');

            // Remove active blocks
            activeCatChild = document.querySelectorAll('.active-cat');
            activeCatChild.forEach(function(child) {
              child.classList.add('fadeOut');
              document.querySelector('.active-cat').remove();
            });

            if (this.parentNode.classList.contains('seasonal-active')) {
              this.parentNode.classList.toggle('seasonal-active');
            }

            if (this.classList.contains('seasonal')) {
              this.parentNode.classList.toggle('seasonal-active');
            }

            // Insert content
            event.classList.add('show');

            // Store the instance
            self = this;

            // Store the active text and icon
            catText = self.querySelector('.card__heading span').attributes[0]
              .value;
            catImage = self.querySelector('.card__picture').attributes[2].value;

            // Swap out text and icon
            self.querySelector('.card__heading span').innerHTML = 'back';
            self.querySelector('.card__picture').src =
              '/assets/reach/icos/back.svg';

            // Insert content
            catChilds = [].slice.call(self.querySelectorAll('.sub-card'));
            list = catChilds.map(function(listItem) {
              return listItem.innerHTML;
            });

            let i = list.length;
            while (i--) {
              const item = list[i];
              const el = document.createElement('div');

              el.innerHTML = item;
              el.setAttribute('class', `card active-cat active-cat__${i}`);
              el.remove();
              self.insertAdjacentHTML('afterend', el.outerHTML);
            }

            isShowing = true;
            altShow = true;
          }
        } else {
          // Third click fix
          if (altShow) {
            // - Reset text,icon and background
            self.querySelector('.card__heading span').innerHTML = catText;
            self.querySelector('.card__picture').src = catImage;
            self.classList.remove('show');

            if (this.parentNode.classList.contains('seasonal-active')) {
              this.parentNode.classList.toggle('seasonal-active');
            }

            // //Remove active blocks
            activeCatChild = document.querySelectorAll('.active-cat');
            activeCatChild.forEach(function(child) {
              child.classList.add('fadeOut');
              document.querySelector('.active-cat').remove();
            });

            if (this.classList.contains('seasonal')) {
              this.parentNode.classList.toggle('seasonal-active');
            }

            altShow = '';
            self = '';
          }

          // # IF true add class the it
          event.classList.add('show');

          // Store the instance
          self = this;

          // Show active and in active categories
          const cardParent = self.parentNode.querySelectorAll('.card');

          cardParent.forEach(function(cards) {
            cards.style.opacity = '0.6';
          });

          // Store the active text and icon
          catText = self.querySelector('.card__heading span').attributes[0]
            .value;
          catImage = self.querySelector('.card__picture').attributes[2].value;

          // Swap out text and icon
          self.querySelector('.card__heading span').innerHTML = 'back';
          self.querySelector('.card__picture').src =
            '/assets/reach/icos/back.svg';

          if (self.parentNode.classList.contains('seasonal-active')) {
            self.parentNode.classList.toggle('seasonal-active');
          }

          if (this.classList.contains('seasonal')) {
            this.parentNode.classList.toggle('seasonal-active');
          }

          // Insert content
          catChilds = [].slice.call(self.querySelectorAll('.sub-card'));
          list = catChilds.map(function(listItem) {
            return listItem.innerHTML;
          });

          let i = list.length;
          while (i--) {
            const item = list[i];
            const el = document.createElement('div');

            el.innerHTML = item;
            el.remove();
            el.setAttribute('class', `card active-cat active-cat__${i}`);
            self.insertAdjacentHTML('afterend', el.outerHTML);
          }

          isShowing = true;
        }
      } else {
        // Do nothing
      }
    });
  });
}
