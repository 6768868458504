import loader from './loader';

let searching = false;

// Return results from search
async function search(query) {

	const res = await queryApi(query);
	let searchResults = ``;
	let showModify = ``;

	if (typeof res !== 'string') {

		res.map((result) => {
			if (typeof uac !== 'undefined') {
				showModify = `<div class="modify">
				<a href='/insights/insights/onepagers/edit/${result._id}'>Edit</a>
				<a href='/insights/insights/onepagers/delete/${result._id}' class="btn-delete")>Delete</a>
				</div>`;
			}

			searchResults += `
				<div class='card market__item'>
					<div class='inner'>
						<h2 class='heading-tertiary'>${result.name}</h2>
						${showModify}
						<p>${result.brand}</p>
						<p>${(result.category) ? result.category : result.region}</p>
						<a href='${result.fileUrl}'>
						<div class='filetypeicon'>
							<div class='icon-${result.fileType}'></div>
						</div>
						</a>
						<a href='${result.fileUrl}' class='btn' download='${result.fileUrl}'><span>Download</span></a>
					</div>
				</div>
			`;

			document.querySelector('.pubresults').style.display = 'flex';
			document.querySelector('.pubresults').innerHTML = searchResults;
			document.querySelector('#onepager').style.display = 'none';

		});

	} else {
		document.querySelector('.pubresults').style.display = 'none';
		document.querySelector('#onepager').style.display = 'flex';

	}

}

// Ajax call
async function queryApi(search) {
	const res = await axios.get(search);
	return await res.data;
}

// Build query string thats sends to API
function buildSearchString() {

	// API values
	const searchTag = '/insights/api/insight?type=onepagers';

	// Get form input
	let searchFilter = document.getElementById('searchtags');
	let searchTagVal = '';

	searchFilter.addEventListener('keydown', (e) => {

		// build search string
		if(searchFilter.value !== '') {
			searchTagVal = searchTag + '&tags=' + encodeURIComponent(searchFilter.value);
			searching = true;

		} else {
			searchTagVal = '';
			searching = false;
		}

		if(e.keyCode == '13') {

			// Get results
			search(searchTagVal);

			e.preventDefault();
		}
	});

	// Prevent form submition on enter
	document.getElementById('SearchForm').addEventListener('submit', (e) => {
		clearResults();
	});

}

// Clear form tags
function clearResults() {

	document.getElementById('resetForm').addEventListener('click', (e) => {
		document.getElementById('SearchForm').reset();
		search('');
		e.preventDefault();
	})

}

function init() {
	buildSearchString();
	clearResults();
}

const insightHubSearchCatI = {
	'init': init
}

export default insightHubSearchCatI;
