// Toggles map label
function init() {
  let domain;
  let allowedDomains = [
    'reachplc.com',
    'trinitymirror.com',
    'localworld.co.uk',
    'dc-media.co.uk',
    'bathchron.co.uk',
    'bvmedia.co.uk',
    'essnmedia.co.uk',
    'lincolnshiremedia.co.uk',
    'targetseries.co.uk',
    'hulldailymail.co.uk',
    'grimsbytelegraph.co.uk',
    'scunthorpetelegraph.co.uk',
    'courier.co.uk',
    'krnmedia.co.uk',
    'leicestermercury.co.uk',
    'uttoxeter-news.co.uk',
    'nottinghampost.com',
    'derbytelegraph.co.uk',
    'heartsessexnews.co.uk',
    'hertsessexnews.co.uk',
    'nng.co.uk',
    'essexchronicle.co.uk',
    'heraldexpress.co.uk',
    'northdevonjournal.co.uk',
    'expressandecho.co.uk',
    'bedfordmidweek.co.uk',
    'cambridge-news.co.uk',
    'cintamworth.co.uk',
    'staffordshirenewspapers.co.uk',
    'thesentinel.co.uk',
    'uttoxeteradvertiser.co.uk',
    'aneventservices.co.uk'
  ];

  const form = document.getElementById('registerForm');

  // Domain registration restriction
  if (typeof userLogin === 'undefined') {

    $('.error-btn').on('click', function (event) {
      event.preventDefault();
      errorModal();
    });

  }

  form.addEventListener('submit', (event) => {
    domain = document.querySelector('.reg .email input').value.split("@")[1];

    if (allowedDomains.indexOf(domain) === -1) {
      // Prevent the form submission
      event.preventDefault();
      document.querySelector('.error').classList.add('show');
      document.querySelector('.reg .email input').style.backgroundColor = '#ca3d40';
      document.querySelector('.reg .email input').style.color = '#fff';
      document.querySelector('.error').innerHTML = 'That email address is not on the list of approved Reach PLC emails, <a href="#">click here</a> to see the allowed domains or contact creativestudio@reachplc.com';

      $('.error a').on('click', function (event) {
        event.preventDefault();
        errorModal();
      });

    }
  })

  // Modal registration poup
  function errorModal() {
    let htmlBody = document.querySelector('body');

    let htmlsource = `<div class="modal modal-error">
			<div class="modal-content">
					<div class="modal-header">
						<div><span class="close-button"></span></div>
					</div>
					<div class="inner">
						<h2 style="">Approved email addresses:</h2>

						<ul class="error-list">
							<li class="error-list--item">reachplc.com</li>
							<li class="error-list--item">trinitymirror.com</li>
							<li class="error-list--item">localworld.co.uk</li>
							<li class="error-list--item">dc-media.co.uk</li>
							<li class="error-list--item">bathchron.co.uk</li>
							<li class="error-list--item">bvmedia.co.uk</li>
							<li class="error-list--item">essnmedia.co.uk</li>
							<li class="error-list--item">lincolnshiremedia.co.uk</li>
							<li class="error-list--item">targetseries.co.uk</li>
							<li class="error-list--item">hulldailymail.co.uk</li>
							<li class="error-list--item">grimsbytelegraph.co.uk</li>
							<li class="error-list--item">scunthorpetelegraph.co.uk</li>
							<li class="error-list--item">courier.co.uk</li>
							<li class="error-list--item">krnmedia.co.uk</li>
							<li class="error-list--item">leicestermercury.co.uk</li>
							<li class="error-list--item">uttoxeter-news.co.uk</li>
							<li class="error-list--item">nottinghampost.com</li>
							<li class="error-list--item">derbytelegraph.co.uk</li>
							<li class="error-list--item">heartsessexnews.co.uk</li>
							<li class="error-list--item">hertsessexnews.co.uk</li>
							<li class="error-list--item">nng.co.uk</li>
							<li class="error-list--item">essexchronicle.co.uk</li>
							<li class="error-list--item">heraldexpress.co.uk</li>
							<li class="error-list--item">northdevonjournal.co.uk</li>
							<li class="error-list--item">expressandecho.co.uk</li>
							<li class="error-list--item">bedfordmidweek.co.uk</li>
							<li class="error-list--item">cambridge-news.co.uk</li>
							<li class="error-list--item">cintamworth.co.uk</li>
							<li class="error-list--item">staffordshirenewspapers.co.uk</li>
							<li class="error-list--item">thesentinel.co.uk</li>
							<li class="error-list--item">uttoxeteradvertiser.co.uk</li>
							<li class="error-list--item">aneventservices.co.uk</li>
						</ul>

						<p>If your email address isn't on the list, please contact <a href="mailto: creativestudio@reachplc.com">creativestudio@reachplc.com</a> to request an account</p>
					</div>
			</div>
		</div>`;

    htmlBody.insertAdjacentHTML('beforeend', htmlsource);

    setTimeout(function () {
      var modal = document.querySelector(".modal");
      var closeButton = document.querySelector(".close-button");

      function toggleModal() {
        modal.classList.toggle("show-modal");
      }

      function modalDestroy() {
        toggleModal();
        setTimeout(function () {
          modal.remove();
        }, 300);
      }

      function windowOnClick(event) {
        if (event.target === modal) {
          modalDestroy();
        }
      }

      // Trigger modal after content is loaded
      toggleModal();
      closeButton.addEventListener("click", modalDestroy);
      window.addEventListener("click", windowOnClick);

    }, 200);
  }
}

const register = {
  "init": init,
}

export default register;