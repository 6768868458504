// Get the folder name
const pageUrl = window.location.pathname;
const folderName = pageUrl.split('/').pop();

// Client ID and API/App ID key from the Developer Console
let API_KEY = 'AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks',
  FOLDER_ID = '18HD6-a1a2Vy-mp6d33OZdsIyGwpkkEzL',
  FOLDER_URL =
    "https://www.googleapis.com/drive/v3/files?q='" +
    FOLDER_ID +
    "'+in+parents&key=" +
    API_KEY +
    '&pageSize=150',
  listParent = '',
  listWrapper = '';

// Show files from shared folder
function getFiles() {
  (listParent = document.getElementById('reportgrab')),
    (listWrapper = document.createElement('ul'));

  axios
    .get(FOLDER_URL)
    .then(function(response) {
      // List of files
      const publicFiles = response.data.files;
      let icon;
      let mapsHTML = [];

      for (let folder in publicFiles) {
        if (publicFiles[folder].name === folderName) {
          const subFolderID = publicFiles[folder].id;
          const subFolderUrl =
            "https://www.googleapis.com/drive/v3/files?q='" +
            subFolderID +
            "'+in+parents&key=" +
            API_KEY +
            '&pageSize=150';

          axios
            .get(subFolderUrl)
            .then(response => {
              // Loop through the files/folders
              for (let file of response.data.files) {
                // Return filename without extension
                let fileName = file.name.replace(/_/g, '').split('.');
                let newName = '';

                // Slices string
                fileName[0].length > 15
                  ? (newName = fileName[0])
                  : (newName = fileName[0]);

                // Push each file as a object for sort purposes
                mapsHTML.push({
                  name: newName,
                  fileid: file.id
                });
              }

              //V2 sort by object key: value
              mapsHTML.sort((a, b) =>
                a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
              );

              // append to DOM
              mapsHTML.map((location, index) => {
                let lastClass = '';

                if ((index + 1) % 4 === 0) {
                  lastClass = 'last';
                } else {
                  lastClass = '';
                }

                let html = `
							<div class="card ${lastClass}">
								<a href="https://drive.google.com/uc?export=download&id=${
                  location.fileid
                }" download class="card__link">
								<div class="card__picture">
									<img src='https://drive.google.com/thumbnail?authuser=0&sz=w500&id=${
                    location.fileid
                  }'   class='' alt='' />
									</div>
									<div class="card__heading">
										<span>${location.name}</span>
									</div>
								</a>

								<span data-source="https://drive.google.com/thumbnail?id=${
                  location.fileid
                }&sz=w720-h720"></span>
							</div>`;
                return listParent.insertAdjacentHTML('beforeend', html);
              });
            })
            .catch(error => console.log(error));
        }
      }
    })
    .catch(function(error) {
      console.log(error);
    });
}

const dataReports = {
  load: getFiles
};

export default dataReports;
