import Chartist from "chartist";
import Vue from "../vue";

// Google Sheet credentials
const id = "16Q0TS7t3uhzwdIMLIqDsb3-bMNGEsQguyOjUz03HTtU";
const key = "AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks";

export const effectivePredictor = () => {
  const vm = new Vue({
    el: "#predictor",
    data() {
      return {
        loading: true,
        selectedCategory: "tv",
        dataReference: [],
        campaignReference: [],
        sidebar: {
          height: 0,
          windowHeight: 0
        },
        selected: {
          consideration2: null,
          consideration: null,
          recommendation: null,
          averageBrandImage: null,
          wordOfMouth: null,
          adRecall: null,
          callToAction: null
        },
        displayHTML: {
          loader: null
        },
        displayPercentage: 0,
        displayCampaigns: 0,
        categories: [
          { name: "TV", value: "tv" },
          { name: "Travel & Transport", value: "travelTransport" },
          { name: "Telecoms", value: "telecoms" },
          { name: "Banking & Finance", value: "bankingFinance" },
          { name: "Betting & Gaming", value: "bettingGaming" },
          { name: "Electronics & Tech", value: "electronicsTech" },
          { name: "Misc", value: "misc" },
          { name: "Motors", value: "motors" },
          { name: "Retail", value: "retail" },
          { name: "Retail - Shop", value: "retailShops" },
          { name: "Retail - Fast Food", value: "retailFood" },
          { name: "Retail - Supermarkets", value: "retailSupermarkets" }
        ]
      };
    },
    methods: {
      sanatiseData: function(array, row, size) {
        if (row === "single") {
          return array.map(item => {
            return item.map(subItem =>
              parseFloat(subItem.replace(/[,|*]/g, ""))
            );
          });
        }

        return array.map(item => {
          // Empty array
          const chunked = [];

          // Starting index
          let index = 0;

          //loop throught array
          while (index < item.length) {
            // Push into array from index + size required
            chunked.push(item.slice(index, index + size));

            // Move onto the next section
            index += size;
          }

          // Remove unwanted value within each array group
          let newChunked = chunked.map(item => {
            return item.map(subItem =>
              parseFloat(subItem.replace(/[,|*]/g, ""))
            );
          });
          return newChunked;
        });
      },
      loadCategory() {
        Promise.all([
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Summary!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/TV!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Travel-Transport!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Telecoms!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Banking-Finance!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Betting-Gaming!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-Supermarkets!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Electronics-Tech!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Misc!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Motors!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-Shops!B2:I2?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-FastFood!B2:I2?majorDimension=COLUMNS&key=${key}`
          )
        ])
          .then(
            ([
              res1,
              res2,
              res3,
              res4,
              res5,
              res6,
              res7,
              res8,
              res9,
              res10,
              res11,
              res12,
              res13
            ]) => {
              const processed = {
                summary: this.sanatiseData(res1.data.values, "single", 0),
                tv: this.sanatiseData(res2.data.values, "single", 0),
                travelTransport: this.sanatiseData(
                  res3.data.values,
                  "single",
                  0
                ),
                telecome: this.sanatiseData(res4.data.values, "single", 0),
                bankingFinance: this.sanatiseData(
                  res5.data.values,
                  "single",
                  0
                ),
                bettingGaming: this.sanatiseData(res6.data.values, "single", 0),
                retailSupermarkets: this.sanatiseData(
                  res7.data.values,
                  "single",
                  0
                ),
                electronicsTech: this.sanatiseData(
                  res8.data.values,
                  "single",
                  0
                ),
                misc: this.sanatiseData(res9.data.values, "single", 0),
                motors: this.sanatiseData(res10.data.values, "single", 0),
                retail: this.sanatiseData(res11.data.values, "single", 0),
                retailShops: this.sanatiseData(res12.data.values, "single", 0),
                retailFood: this.sanatiseData(res13.data.values, "single", 0)
              };

              this.dataReference = processed;
              return true;
            }
          )
          .then(data => {
            this.countRows();
          })
          .catch(err => console.log(err));
      },
      countRows() {
        Promise.all([
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/TV!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Travel-Transport!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Telecoms!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Banking-Finance!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Betting-Gaming!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-Supermarkets!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Electronics-Tech!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Misc!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Motors!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-Shops!A6:A100?majorDimension=COLUMNS&key=${key}`
          ),
          axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Retail-FastFood!A6:A100?majorDimension=COLUMNS&key=${key}`
          )
        ])
          .then(
            ([
              res1,
              res2,
              res3,
              res4,
              res5,
              res6,
              res7,
              res8,
              res9,
              res10,
              res11,
              res12
            ]) => {
              const campaigns = {
                tv: res1.data.values[0].length,
                travelTransport: res2.data.values[0].length,
                telecome: res3.data.values[0].length,

                bankingFinance: res4.data.values[0].length,
                bettingGaming: res5.data.values[0].length,
                retailSupermarkets: res6.data.values[0].length,

                electronicsTech: res7.data.values[0].length,
                misc: res8.data.values[0].length,
                motors: res9.data.values[0].length,

                retail: res10.data.values[0].length,
                retailShops: res11.data.values[0].length,
                retailFood: res12.data.values[0].length
              };

              this.campaignReference = campaigns;
            }
          )
          .then(() => {
            this.loading = false;
          })
          .then(() => {
            this.handleChange();
          })
          .catch(error => console.log(error));
      },
      handleChange() {
        // Reset to default
        this.displayPercentage = 0;
        this.displayCampaigns = 0;

        this.selected.consideration2 = [
          this.dataReference.summary[0],
          this.dataReference[this.selectedCategory][0]
        ];
        this.selected.consideration = this.dataReference[
          this.selectedCategory
        ][0];

        this.selected.recommendation = this.dataReference[
          this.selectedCategory
        ][1];

        this.selected.averageBrandImage = this.dataReference[
          this.selectedCategory
        ][2];

        this.selected.wordOfMouth = this.dataReference[
          this.selectedCategory
        ][3];

        this.selected.adRecall = this.dataReference[this.selectedCategory][4];

        this.selected.callToAction = this.dataReference[
          this.selectedCategory
        ][5];

        this.displayCampaigns = this.campaignReference[this.selectedCategory];

        const displayPercentage =
          this.dataReference[this.selectedCategory][7] &&
          this.dataReference[this.selectedCategory][7];

        // Adscore
        if (displayPercentage)
          this.displayPercentage = this.dataReference[
            this.selectedCategory
          ][7][0];

        // Consideration;
        const consideration = this.formatChart("chartA", "consideration", 0);
        // recommendation
        const recommendation = this.formatChart("chartB", "recommendation", 1);
        // averageBrandImage
        const averageBrand = this.formatChart("chartC", "averageBrandImage", 2);
        // Word of mouth
        const wordOfMouth = this.formatChart("chartD", "wordOfMouth", 3);
        // Ad Recall
        const adRecall = this.formatChart("chartE", "adRecall", 4);
        // Call To Action
        const callToAction = this.formatChart("chartF", "callToAction", 5);
      },
      formatChart: function(elem, data, summaryIndex) {
        const chartData = new Chartist.Bar(
          this.$refs[elem],
          {
            labels: [
              this.dataReference.summary[summaryIndex] + "%",
              this.selected[data] + "%"
            ],
            series: [
              this.dataReference.summary[summaryIndex].map(item => {
                return { value: item };
              }),
              this.selected[data]
            ]
          },
          {
            width: "100%",
            fullWidth: true,
            axisY: { showLabel: false, showGrid: true },
            axisX: { showGrid: false, showLabel: true }
          }
        );
      },
      calcualteSidebar() {
        // this.sidebar.height = this.$refs.sidebarContent.getBoundingClientRect().top;
        // this.sidebar.windowHeight = window.pageYOffset;
      },
      loaderHTML: function() {
        const loader = `
        <div class="datahub-loader">
          <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#4977de"
        >
          <g fill="none" fill-rule="evenodd" stroke-width="2">
            <circle cx="22" cy="22" r="1">
              <animate
                attributeName="r"
                begin="0s"
                dur="1.8s"
                values="1; 20"
                calcMode="spline"
                keyTimes="0; 1"
                keySplines="0.165, 0.84, 0.44, 1"
                repeatCount="indefinite"
              />
              <animate
                attributeName="stroke-opacity"
                begin="0s"
                dur="1.8s"
                values="1; 0"
                calcMode="spline"
                keyTimes="0; 1"
                keySplines="0.3, 0.61, 0.355, 1"
                repeatCount="indefinite"
              />
            </circle>
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        </div>
        `;

        this.displayHTML.loader = loader;
      }
    },

    created() {
      window.addEventListener("scroll", this.handleScroll);
    },

    mounted() {
      // store sidebar and window top
      this.calcualteSidebar();

      //Displays loader UI
      this.loaderHTML();

      // Default category
      this.loadCategory();
    },

    computed: {
      sidebarClass() {
        if (this.sidebar.height < this.sidebar.windowHeight - 140)
          return { "is-fixed": true };
      },
      categoryTitle() {
        return this.categories.filter(
          cat => cat.value === this.selectedCategory
        )[0].name;
      }
    },

    watch: {
      selectedCategory() {
        this.handleChange();
      }
    }
  });

  // VueJS Instance
  return vm;
};
