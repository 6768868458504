
// Client ID and API/App ID key from the Developer Console
let API_KEY 		= 'AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks',
	FOLDER_ID 	= '1MaAXQG8sKZMHm5504RKLNg3gzNNP1fEh',
	FOLDER_URL 	= "https://www.googleapis.com/drive/v3/files?q='" + FOLDER_ID + "'+in+parents&key=" + API_KEY + '&pageSize=150',
	listParent = '',
	listWrapper = '';

// Show files from shared folder
function getSlide() {

		// Icon reference
      const fileTypeIcon = {
			'fa-file-powerpoint': ['application/vnd.oasis.opendocument.presentation-template', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
			'fa-pencil-alt': ['application/postscript', 'image/vnd.adobe.photoshop'],
			'fa-table': ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
			'fa-folder': ['application/vnd.google-apps.folder'],
			'fa-file-alt': ['text/plain', 'application/msword', 'text/rtf'],
			'fa-file-archive': ['application/zip'],
			'fa-code': ['text/html', 'text/php', 'application/javascript'],
      	'fa-file-pdf': ['application/pdf'],
      	'fa-image' : ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml']
		};

	listParent 			= document.getElementById('sub-cards'),
	listWrapper 		= document.createElement('ul');

	axios.get(FOLDER_URL)
	.then(function(response) {

		// List of files
      const publicFiles = response.data.files;
      let icon;
      let mapsHTML = [];

		// Loop through the files/folders
      for(let file of publicFiles) {

			// Check file mimeType for different icons
			for (const key of Object.keys(fileTypeIcon)) {
				for(let iconName of fileTypeIcon[key]) {
					if(file.mimeType === iconName) {
						icon = key;
					}
				}
         }

         // Return filename without extension
         let fileName = file.name.replace(/_/g, '').split('.');
			let newName = '';

         // Slices string
			fileName[0].length > 15 ? newName = fileName[0] : newName = fileName[0];

         // Push each file as a object for sort purposes
         mapsHTML.push({ name: newName, fileid: file.id, filetype: `alt-icon-${file.mimeType}`});

     }

      //V2 sort by object key: value
      mapsHTML.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);

      // append to DOM
      mapsHTML.map((location, index) => {
			let lastClass = '';
			if((index + 1) % 4 === 0) {
				lastClass = 'last';
			}

         let html = `
            <div class="card ${lastClass}">
               <a href="https://drive.google.com/uc?export=download&id=${location.fileid}" download class="card__link">
                  <img src='https://drive.google.com/thumbnail?authuser=0&sz=w500&id=${location.fileid}'   class='card__picture' alt='' />
                  <div class="card__heading">
                     <div class="${location.filetype} fa-2x"> </div><span>${location.name}</span>
                  </div>
               </a>
            </div>`;
         return listParent.insertAdjacentHTML('beforeend', html);
      });

	})
	.catch(function(error) {
		console.log(error);
	});
}

const nickableSlide = {
	"init": getSlide
}

export default nickableSlide;