import GLightbox from 'glightbox';

function init() {
  const lightbox = GLightbox({
    touchNavigation: true,
    openEffect: 'fade',
    width: 900,
    height: 'auto',
    descPosition: 'right',
    skin: 'content-lightbox',
    moreLength: 0,
  });
}

export default init;
