import 'slick-carousel';

function insightsInit() {
  $('#reelCaro').slick({
    centerPadding: '0',
    centerMode: true,
    mobileFirst: true,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    rows: 0
  });
}
  const insightsHome = {
    "insightsInit": insightsInit,
  }

export default insightsHome;