const id = '1z7Y6_dnWSXpxyIaWOiMothJUf8tqOCH8atEPybute3M';
const key = 'AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks';
let listParent = '';

function getVideo() {
  axios
    .get(
      `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/video!A2:B100?majorDimension=ROWS&key=${key}`
    )
    .then(res => {
      listParent = document.getElementById('sub-cards');

      const  reversedArray = res.data.values.reverse();
 
      reversedArray.forEach(youtube => {
        const videoTitle = youtube[0];
        const videoUrl = youtube[1];

        let html = `
          <div class="video-card">
            <iframe id="youtube" width="960" height="540" src="https://www.youtube.com/embed/${videoUrl}?showinfo=0" frameborder="0" allowfullscreen></iframe>
            <div class="card-title">${videoTitle}</div>
          </div>
        `;

        return listParent.insertAdjacentHTML('beforeend', html);
      });
    })
    .catch(error => console.log(error));
}

const ourVideo = {
  init: getVideo
};

export default ourVideo;
