import loader from './loader';

/*
Very impoprtant;
inorder for any to end upload a file to the shared folder it must be SHARED WITH THEM
*/

function newInsights() {
  initPage();
}

/*Google Drive - START*/

// Define Credentials
let CLIENT_ID =
    '267603030216-mjgibttmgvkpfjv7rfiaivlhc002j057.apps.googleusercontent.com',
  API_KEY = 'AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks',
  APP_ID = 'sandbox-179709',
  SCOPES = 'https://www.googleapis.com/auth/drive',
  DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v2/rest'
  ],
  pickerApiLoaded = false,
  oauthToken,
  deleteFile;

const folderNames = {
  effectiveness: 'Effectiveness',
  onepagers: 'CategoryInsights',
  package: 'PackagesGrabSheet',
  scotland: 'scotland',
  northwest: 'northwest',
  westmidlands: 'westmidlands',
  wales: 'wales',
  southwest: 'southwest',
  northeast: 'northeast',
  yorkshire: 'yorkshire',
  eastmidlands: 'eastmidlands',
  southeast: 'southeast',
  london: 'london',
  eastofengland: 'eastofengland',
  national: 'national',
  bigcity: 'bigcity',
  bigbusiness: 'bigbusiness',
  bigsunday: 'bigsunday',
  biglocal: 'biglocal',
  bigbritain: 'bigbritain',
  magsups: 'magsups',
  reachstats: 'reachstats',
  sportsbetting: 'sportsbetting',
  bettinggaming: 'bettinggaming',
  charity: 'charity',
  tech: 'tech',
  leisure: 'leisure',
  finance: 'finance',
  houseappliances: 'houseappliances',
  motors: 'motors',
  retail: 'retail',
  supermarkets: 'supermarkets',
  telecoms: 'telecoms',
  travel: 'travel',
  misc: 'misc',
  seasonal: 'seasonal',
  healthfitness: 'healthfitness',
  education: 'education',
  industry: 'industry'
};

/**
 *  On load, called to load the auth2 library and API client library.
 */
function handleClientLoad() {
  gapi.load('client:auth2', initClient);
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
function initClient() {
  gapi.client
    .init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    })
    .then(function() {
      // Listen for sign-in state changes.
      gapi.auth2.getAuthInstance().isSignedIn.listen(removeFile);
      // Handle the initial sign-in state.
      updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    });
}

function handleAuthClick(event) {
  gapi.auth2.getAuthInstance().signIn();
}

/**
 *  Called when the signed in status changes, to update the UI
 *  appropriately. After a sign-in, the API is called.
 */
function updateSigninStatus(isSignedIn) {
  if (isSignedIn) {
    deleteFile.addEventListener('click', function(event) {
      setTimeout(function() {
        removeFile(); // Add picker/delete button to DOM
      }, 1000);
    });
  } else {
    deleteFile.addEventListener('click', function(event) {
      handleAuthClick();
    });
  }
}

// Work around for teh above, mart the file for deletion. App script runs as admin and deletes the file
function submitDeleteRequest(fileId, newTitle) {
  let body = { title: newTitle };
  let request = gapi.client.drive.files.patch({
    fileId: fileId,
    resource: body
  });
  request.execute(function(resp) {
    // REset the URl
    deleteFile.parentNode.innerHTML = `
		<span class="drive-upload btn-alt btn-alt--grey">Choose a File</span>
			<input type='hidden' name='insights[fileUrl]' id='fileurl'>
			<input type='hidden' name='insights[fileType]' id='filetype'>
		`;
  });
}

// Remove file
function removeFile() {
  // Add confirm box
  let body = document.querySelector('body');
  let fielId = document
    .querySelector('.remove-file span')
    .getAttribute('data-id');

  let confirmBody = `
		<div class="dialog-box">
			<div class="dialog-body">
				<div class="dialog-head">
					Confirm
					<a class="dialog-close">Close</a>
				</div>
				<div class="dialog-wrap">
					<p class="copy">Are you sure you want to delete this file?</p>
					<div class='action-group'>
						<button class="yes">Delete</button>
						<button class="no">Cancel</button>
					</div>
				</div>
			</div>
		</div>`;

  body.insertAdjacentHTML('beforeend', confirmBody);

  document.querySelector('.dialog-box').addEventListener('click', function(el) {
    if (el.target.classList.contains('yes')) {
      // Delete file
      submitDeleteRequest(fielId, 'delete_me');
      // Remove dialog once clicked
      setTimeout(function() {
        document.querySelector('.dialog-box').remove();
      }, 200);
    } else if (
      el.target.classList.contains('no') ||
      el.target.classList.contains('dialog-close')
    ) {
      // Close dialog
      document.querySelector('.dialog-box').remove();
    }
  });
}

// Use the Google API Loader script to load the google.picker script.
function onApiLoad() {
  gapi.load('auth', { callback: onAuthApiLoad });
  gapi.load('picker', { callback: onPickerApiLoad });
}

function onAuthApiLoad() {
  window.gapi.auth.authorize(
    {
      client_id: CLIENT_ID,
      scope: SCOPES,
      immediate: false
    },
    handleAuthResult
  );
}

function onPickerApiLoad() {
  pickerApiLoaded = true;
  createPicker();
}

function handleAuthResult(authResult) {
  if (authResult && !authResult.error) {
    oauthToken = authResult.access_token;
    createPicker();
  }
}

// Create and render a Picker object for searching images.
function createPicker() {
  if (pickerApiLoaded && oauthToken) {
    // Set Parent
    let uploadView = new google.picker.DocsUploadView();

    let picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .addView(uploadView)
      .addView(uploadView.setParent('1_0Wgjacg6FygAxmw-9w4w8Mcw1eZy2mI'))
      .hideTitleBar()
      .setAppId(APP_ID)
      .setOAuthToken(oauthToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  }
}

// A simple callback implementation.
function pickerCallback(data) {
  // Check if a file has been uploaded

  if (data.action == google.picker.Action.PICKED) {
    loader.load();
    // let fileId = data.docs[0].id;
    let fileURL = document.querySelector('#fileurl');
    let fileType = document.getElementById('filetype');
    let parentFolder, subFolder;

    // Sets the field value
    let SCRIPT_URL =
      'https://script.google.com/macros/s/AKfycbzqxFplAide-TcZdv3ST1o3C1bU0AtHtaH9TeKrKC1gQ4mINyE/exec';

    // Need to wait for the file to upload, cant do promise or await, or add sertime to g script
    setTimeout(function() {
      $(document).ready(function() {
        if (document.getElementById('newInsightPage')) {
          parentFolder = document.getElementById('elemSelector').value;
        }

        // Find out which subfolder group to get
        if (parentFolder === 'effectiveness') {
          subFolder = document.getElementById('cat-effective').value;
        } else if (parentFolder === 'onepagers') {
          subFolder = document.getElementById('cat-standard').value;
        } else {
          subFolder = document.getElementById('cat-packages').value;
        }

        $.getJSON(
          SCRIPT_URL + '?callback=?',
          {
            method: 'distribute_file',
            folder: folderNames[parentFolder],
            subFolder: folderNames[subFolder]
          },
          function(data) {
            // Returns file id or msg if file already exists
            if (data.id) {
              let uploadBtn = document.querySelector(
                  '#insightform .drive-upload '
                ),
                submitBtn = document.querySelector('#insightform .btn');

              // Re-enable the submit button
              submitBtn.removeAttribute('disabled');
              uploadBtn.classList.remove(
                'drive-upload',
                'btn-alt--grey',
                'btn-alt'
              );
              uploadBtn.classList.add('drive-disable');
              uploadBtn.innerHTML = `Upload file: ${data.name}`;
              uploadBtn.parentNode.insertAdjacentHTML(
                'beforeend',
                `<div class="remove-file"><span data-id="${
                  data.id
                }" class="btn-alt btn-alt--grey btn-alt--small">Delete File</span></div>`
              );

              fileURL.value = `https://drive.google.com/uc?export=download&id=${
                data.id
              }`;
              fileType.value = data.fileType;
              loader.ready();

              // Update the Dom for click event
              deleteFile = document.querySelector('.remove-file');

              // Init delete request
              handleClientLoad();
            } else {
              console.log(data);
            }
          }
        );
      });
    }, 0);
  }
}

/*Google Drive - END */

const newInsightController = {
  newInsights: newInsights
};

function changeType() {
  const myForm = document.getElementById('insightform');
  const effective = document.getElementsByClassName('eff');
  const both = document.getElementsByClassName('both');
  const region = document.getElementById('region');
  const catInsight = document.getElementById('cat-insight');

  if (myForm.myType.value === 'effectiveness') {
    for (let item of effective) {
      item.style.display = 'block';
    }
    for (let item of both) {
      item.style.display = 'block';
    }
    region.style.display = 'none';
    catInsight.style.display = 'none';
  } else if (myForm.myType.value === 'onepagers') {
    for (let item of effective) {
      item.style.display = 'none';
    }
    for (let item of both) {
      item.style.display = 'block';
    }
    region.style.display = 'none';
    catInsight.style.display = 'block';
  } else if (myForm.myType.value === 'package') {
    for (let item of effective) {
      item.style.display = 'none';
    }
    for (let item of both) {
      item.style.display = 'none';
    }
    region.style.display = 'block';
    catInsight.style.display = 'none';
  } else {
    myForm.myType.value = 'effectiveness';
    changeType();
  }
}

function initPage() {
  if (document.getElementById('newInsightPage')) {
    document
      .getElementById('elemSelector')
      .addEventListener('change', function(e) {
        changeType();
      });

    if (document.getElementById('newInsightPage')) {
      changeType();
    }
  }

  document
    .querySelector('#insightform')
    .addEventListener('click', function(el) {
      if (el.target.classList.contains('drive-upload')) {
        onApiLoad();
      }
    });
}

export default newInsightController;
