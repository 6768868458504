import slick from 'slick-carousel';
import axios from 'axios';

function returnVideoModalContent(content) {
  return `
    <div class="modal-content">
      <div class="inner">
        ${content}
      </div>
    </div>
  `;
}

function returnGalleryContent(path) {
  return `
    <div class="modal-content content-modal-gallery">
      <div class="inner">
        <div class="main">
          <img class="mainImg" src="${path}1.jpg" />
        </div>
        <div class="sub">
          <img class="modalsubnav" data-img="${path}1.jpg" src="${path}1.jpg" />
          <img class="modalsubnav" data-img="${path}2.jpg" src="${path}2.jpg" />
          <img class="modalsubnav" data-img="${path}3.jpg" src="${path}3.jpg" />
        </div>
      </div>
    </div>
  `;
}

const registerNavEvents = () => {
  const main = document.querySelector('.mainImg');
  document.querySelectorAll('.modalsubnav').forEach(navElem => {
    navElem.addEventListener('click', e => {
      main.src = e.target.dataset.img;
    });
  });
}


function generateModal(id, type) {
  console.log(id);
  const modal = document.getElementById('modal');
  modal.classList.add('show-modal');

  if(type === 'video'){
    axios.get(`/api/content/videos/${id}`).then(res => {
      modal.innerHTML = returnVideoModalContent(res.data.code);
    });
  } else {
    modal.innerHTML = returnGalleryContent(id);
    registerNavEvents();
  }

  function closeModal(e) {
    if (e.target === modal) {
      modal.classList.remove('show-modal');
      modal.innerHTML = '';
    }
  }

  $(window).on('click', closeModal);
}

function init() {
  $('.videos').slick({
    centerPadding: '0',
    centerMode: true,
    prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
    nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
    mobileFirst: true,
    slidesToShow: 2,
    arrows: true,
    draggable: false,
    swipeToSlide: true,
    rows: 0,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  });

  const slideElems = document.querySelectorAll('.video');
  const imageElems = document.querySelectorAll('.layout');

  slideElems.forEach(elem => {
    elem.addEventListener('click', e => {
      e.preventDefault();
      generateModal(e.target.dataset.link, 'video');
    });
  });

  imageElems.forEach(elem => {
    elem.addEventListener('click', e => {
      e.preventDefault();
      console.log(e.target);
      generateModal(e.target.dataset.link, 'image');
    });
  })

}

export default init;
