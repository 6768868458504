import axios from 'axios';

function init() {
  const domName = document.getElementById('name');
  const domUrl = document.getElementById('url');
  const domImg = document.getElementById('preview');

  document.getElementById('embed').addEventListener('change', e => {
    const embed = e.target.value;
    const match = embed.match(/player\.vimeo\.com\/video\/([0-9]*)/);

    if (match) {
      axios.get(`https://vimeo.com/api/v2/video/${match[1]}.json`).then(res => {
        const videoInfo = res.data[0];
        domName.value = videoInfo.title;
        domUrl.value = videoInfo.thumbnail_large;
        domImg.src = videoInfo.thumbnail_large;
      });
    } else {
      domName.value = '';
      domUrl.value = '';
      domImg.src = '';
    }
  });
}

export default init;
