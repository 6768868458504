const id = "1cs-293V4q39CI54DfYtL5GLnn0-bzO00BafVEDjx2gI";
const key = "AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks";
let postsParent = "";
let titleParent = "";
let imgParent = "";
let categoryParent = "";
let CTAParent = "";
let category = "";
let posts = null;
let postData = [];

function insertText(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i].title !== undefined && data[i].content !== undefined && data[i].cta !== undefined ) {
      let imgHtml = "";
      if (typeof data[i].img !== "undefined") {
        imgHtml = `<img class="style_image" src="${data[i].img}" alt="In Your Area" />`;
      } else {
        imgHtml = `<img class="style_image" src="/assets/iya/iya.jpg" alt="In Your Area" />`;
      }
      if (titleParent[i]) {
        titleParent[i].insertAdjacentHTML("beforeend", data[i].title);
      }
      if (imgParent[i]) {
        imgParent[i].insertAdjacentHTML("beforeend", imgHtml);
      }
      if (postsParent[i]) {
        postsParent[i].insertAdjacentHTML("beforeend", data[i].content);
      }
      if (CTAParent[i]) {
       CTAParent[i].insertAdjacentHTML("beforeend", data[i].cta);
      }
    }
  }
}

function pushPosts(posts, cat) {
console.log(posts)
  posts.map((post) => {
    if (post[0] === cat) {
      postData.push({
        category: post[0],
        title: post[1],
        content: post[3],
        cta: post[5],
        img: post[6],
      });
    }
  });

}

export function getPostText() {
  axios
    .get(
      `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Sheet1!A1:M100?majorDimension=ROWS&key=${key}`
    )
    .then((res) => {
      posts = res.data.values;
      // remove header array
      posts.shift();

      categoryParent = document.getElementById("iya-posts");
      postsParent = document.getElementsByClassName("style_content");
      titleParent = document.getElementsByClassName("style_contentTitle");
      imgParent = document.getElementsByClassName("img-container");
      CTAParent = document.getElementsByClassName("style_cta");
      category = categoryParent.firstChild.id;

      if (category === "iya-cat-funeral-directors") {
        pushPosts(posts, "Funeral Directors");
        insertText(postData);
      } else if (category === "iya-cat-plumbers") {
        pushPosts(posts, "Plumbers");
        insertText(postData);
      } else if (category === "iya-cat-mechanics") {
        pushPosts(posts, "Mechanics");
        insertText(postData);
      } else if (category === "iya-cat-electricians") {
        pushPosts(posts, "Electricians");
        insertText(postData);
      } else if (category === "iya-cat-scrap") {
        pushPosts(posts, "Scrap");
        insertText(postData);
      } else if (category === "iya-cat-gardening") {
        pushPosts(posts, "Gardening");
        insertText(postData);
      } else if (category === "iya-cat-property") {
        pushPosts(posts, "Property");
        insertText(postData);
      } else if (category === "iya-cat-education") {
        pushPosts(posts, "Education");
        insertText(postData);
      } else if (category === "iya-cat-auctions") {
        pushPosts(posts, "Auctions");
        insertText(postData);
      } else if (category === "iya-cat-roofing") {
        pushPosts(posts, "Roofing");
        insertText(postData);
      } else if (category === "iya-cat-windows-and-blinds") {
        pushPosts(posts, "Windows & Blinds");
        insertText(postData);
      } else if (category === "iya-cat-retail") {
        pushPosts(posts, "Retail");
        insertText(postData);
      } else if (category === "iya-cat-business") {
        pushPosts(posts, "Business");
        insertText(postData);
      } else if (category === "iya-cat-leisure") {
        pushPosts(posts, "Leisure");
        insertText(postData);
      } else if (category === "iya-cat-recruitment") {
        pushPosts(posts, "Recruitment");
        insertText(postData);
      } else if (category === "iya-cat-food") {
        pushPosts(posts, "Food");
        insertText(postData);
      } else if (category === "iya-cat-health-beauty") {
        pushPosts(posts, "Health & Beauty");
        insertText(postData);
      } else if (category === "iya-cat-agriculture") {
        pushPosts(posts, "Agriculture");
        insertText(postData);
      } else if (category === "iya-cat-security") {
        pushPosts(posts, "Security");
        insertText(postData);
      } else if (category === "iya-cat-removals") {
        pushPosts(posts, "Removals");
        insertText(postData);
      } else if (category === "iya-cat-community") {
        pushPosts(posts, "Community");
        insertText(postData);
      } 
      return;
    })
    .catch((error) => console.log(error));
}

const IYAPosts = {
  init: getPostText,
};

export default IYAPosts;
