
import tippy from 'tippy.js';

// Regional map tooltip
function reachMap() {
  let mapRegions = document.querySelectorAll('#south-west_1_, #north-east_1_, #north-west_1_, #humber_1_, #east-mids_1_, #wales_1_, #west-mids_1_, #east-anglia_1_, #london_1_, #south-east_1_, #scotland_1_, #northireland');

  mapRegions.forEach(location => {
    location.addEventListener('mouseover', function(el) {
      tippy(location, { content: location.dataset.name });
    });
  });

}

const regionalMap = {
  "map": reachMap,
}

export default regionalMap;