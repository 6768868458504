const id = "1LjQzhRfuF5keUu9ilH3uhx0GJzODmZAy2uPOGTtLfSw";
const key = "AIzaSyAcN4JYFYkeGUbxLB-dUgnD4L9D_77ogks";
let postsParent = "";
let titleParent = "";
let imgParent = "";
let avatar = "";
let accountName = "";
let categoryParent = "";
let CTAParent = "";
let category = "";
let posts = null;
let postData = [];

function insertText(data) {
  console.log(data)
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i].title !== undefined && data[i].content !== undefined && data[i].cta !== undefined) {
      let imgHtml = "";
      if (typeof data[i].img !== "undefined") {
        imgHtml = `<img class="style_image" src="${data[i].img}" alt="Facebook" />`;
      } else {
        imgHtml = `<img class="style_image" src="/assets/fb/fb.jpg" alt="Facebook" />`;
      }
      if (titleParent[i]) {
        titleParent[i].insertAdjacentHTML("beforeend", data[i].title);
      }
      let avatarHtml = "";
      if (typeof data[i].avatar !== "undefined") {
        avatarHtml = `<img class="style_avatar" src="${data[i].avatar}" alt="Facebook" />`;
      }

      console.log(imgParent)
      if (avatar[i]) {
        avatar[i].insertAdjacentHTML("beforeend", avatarHtml);
      }
      if (accountName[i]) {
        accountName[i].insertAdjacentHTML("beforeend", data[i].accountName);
      }
      if (imgParent[i]) {
        imgParent[i].insertAdjacentHTML("beforeend", imgHtml);
      }
      if (postsParent[i]) {
        postsParent[i].insertAdjacentHTML("beforeend", data[i].content);
      }
      if (CTAParent[i]) {
        CTAParent[i].insertAdjacentHTML("beforeend", data[i].cta);
      }
    }
  }
}

function pushPosts(posts, cat) {
  posts.map((post) => {
    if (post[0] === cat) {
      postData.push({
        category: post[0],
        title: post[1],
        content: post[3],
        cta: post[5],
        img: post[6],
        accountName: post[7],
        avatar: post[8],
      });
    }
  });

}

export function getPostText() {
  axios
    .get(`https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Sheet1!A1:M100?majorDimension=ROWS&key=${key}`)
    .then((res) => {
      posts = res.data.values;
      // remove header array
      posts.shift();

      categoryParent = document.getElementById("fb-posts");
      postsParent = document.getElementsByClassName("style_content");
      avatar = document.getElementsByClassName("style_avatar-container");
      accountName = document.getElementsByClassName("account-name");
      titleParent = document.getElementsByClassName("style_contentTitle");
      imgParent = document.getElementsByClassName("img-container");
      CTAParent = document.getElementsByClassName("style_cta");
      category = categoryParent.firstChild.id;

      if (category === "fb-cat-funeral-directors") {
        pushPosts(posts, "Funeral Directors");
        insertText(postData);
      } else if (category === "fb-cat-plumbers") {
        pushPosts(posts, "Plumbers");
        insertText(postData);
      } else if (category === "fb-cat-mechanics") {
        pushPosts(posts, "Mechanics");
        insertText(postData);
      } else if (category === "fb-cat-electricians") {
        pushPosts(posts, "Electricians");
        insertText(postData);
      } else if (category === "fb-cat-scrap") {
        pushPosts(posts, "Scrap");
        insertText(postData);
      } else if (category === "fb-cat-gardening") {
        pushPosts(posts, "Gardening");
        insertText(postData);
      } else if (category === "fb-cat-property") {
        pushPosts(posts, "Property");
        insertText(postData);
      } else if (category === "fb-cat-education") {
        pushPosts(posts, "Education");
        insertText(postData);
      } else if (category === "fb-cat-auctions") {
        pushPosts(posts, "Auctions");
        insertText(postData);
      } else if (category === "fb-cat-roofing") {
        pushPosts(posts, "Roofing");
        insertText(postData);
      } else if (category === "fb-cat-windows-and-blinds") {
        pushPosts(posts, "Windows & Blinds");
        insertText(postData);
      } else if (category === "fb-cat-retail") {
        pushPosts(posts, "Retail");
        insertText(postData);
      } else if (category === "fb-cat-business") {
        pushPosts(posts, "Business");
        insertText(postData);
      } else if (category === "fb-cat-leisure") {
        pushPosts(posts, "Leisure");
        insertText(postData);
      } else if (category === "fb-cat-recruitment") {
        pushPosts(posts, "Recruitment");
        insertText(postData);
      } else if (category === "fb-cat-food") {
        pushPosts(posts, "Food");
        insertText(postData);
      } else if (category === "fb-cat-health-beauty") {
        pushPosts(posts, "Health & Beauty");
        insertText(postData);
      } else if (category === "fb-cat-agriculture") {
        pushPosts(posts, "Agriculture");
        insertText(postData);
      } else if (category === "fb-cat-security") {
        pushPosts(posts, "Security");
        insertText(postData);
      } else if (category === "fb-cat-removals") {
        pushPosts(posts, "Removals");
        insertText(postData);
      }
      return;
    })
    .catch((error) => console.log(error));
}

console.log(postData)

const FBPosts = {
  init: getPostText,
};



export default FBPosts;
